import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../../components/affix/AutoAffix";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

import FormContatti from "../../../components/form-contatti";

import Square from "../../../svg/square-det.svg";

const hero = [
  {
    title: "Cloud Native Apps",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "AWS", link: "/competence-center/aws/" },
  { title: "Cloud Native Apps" },
];

const CloudNativeApps = ({ data, location }) => {
  const { vantaggi, perche, modelli, aws } = data;

  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={`Cloud Native Apps`}
        description={`Ariadne, da sempre attenta all’evoluzione delle tecnologie web per la realizzazione di portali di tipo Enterprise, ha sviluppato competenze tecniche sui servizi cloud Amazon Web Services`}
      />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                Ariadne, da sempre attenta all’evoluzione delle tecnologie web per la realizzazione
                di portali di tipo Enterprise, ha sviluppato competenze tecniche sui servizi cloud
                Amazon Web Services per la progettazione di applicazioni Cloud native.
              </p>
              <p>
                Tale scelta consente di offrire lo stato dell’arte della tecnologia nello sviluppo
                di progetti digitali innovativi che tengano conto di nuovi paradigmi nella gestione
                e analisi dei dati.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "aws-cloud-native-app-perche-adottarle",
                        "cloud-native-app-i-vantaggi-di-unarchitettura-basata-sui-microservizi",
                        "perche-aws",
                        "progetti-realizzati-su-liferay-dxp",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#aws-cloud-native-app-perche-adottarle`}
                          to={`aws-cloud-native-app-perche-adottarle`}
                          className="anchor-nav__link"
                          title="AWS: Cloud Native App, perchè adottarle?"
                        >
                          AWS: Cloud Native App, perchè adottarle?
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#cloud-native-app-i-vantaggi-di-unarchitettura-basata-sui-microservizi`}
                          to={`cloud-native-app-i-vantaggi-di-unarchitettura-basata-sui-microservizi`}
                          className="anchor-nav__link"
                          title="Cloud Native App: i vantaggi di un'architettura basata sui microservizi"
                        >
                          Cloud Native App: i vantaggi di un'architettura basata sui microservizi
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#perche-aws`}
                          to={`perche-aws`}
                          className="anchor-nav__link"
                          title="Perché AWS?"
                        >
                          Perché AWS?
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#progetti-realizzati-su-liferay-dxp`}
                          to={`progetti-realizzati-su-liferay-dxp`}
                          className="anchor-nav__link"
                          title="Il modello dei prezzi AWS"
                        >
                          Il modello dei prezzi AWS
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="aws-cloud-native-app-perche-adottarle" className="anchor">
                  <GatsbyImage image={getImage(aws)} alt="Image aws" className="anchor-text__img" />
                  <h2 className="anchor-text__title">AWS: Cloud Native App, perchè adottarle?</h2>
                  <p>
                    Quando si ha l’esigenza di una nuova applicazione utile al processo di
                    digitalizzazione dell’azienda, si prendono in esame diverse soluzioni in base a
                    criteri sia di business che tecnici.
                  </p>
                  <p>
                    Le nuove tendenze vedono le cloud native apps come la migliore delle soluzioni.
                    Ma perchè? Vediamo insieme quali sono i <strong>vantaggi oggettivi</strong> per
                    i quali l’utilizzo di infrastrutture basate sui <strong>microservizi</strong> si
                    rivela un approccio vincente.
                  </p>
                  <p>
                    <strong>Il Cloud come abilitatore tecnologico</strong>
                  </p>
                  <p>
                    “Cloud is the new web”. E' il posto dove bisogna essere se si vuole stare al
                    passo con la digital transformation. L’utilizzo del Cloud apre le porte dell’
                    <strong>innovazione tecnologica</strong> ad aziende grandi e piccole che, grazie
                    ad una infrastruttura già pronta con servizi sempre disponibili, possono testare
                    e poi sviluppare <strong>sistemi all’avanguardia</strong>. Un progetto
                    innovativo è per definizione ad alto rischio; se sono necessari budget
                    importanti per avviarlo rischia di non partire nemmeno ed è proprio in questo
                    che il cloud è abilitatore di innovazione, perchè permette di “provare” a{" "}
                    <strong>basso costo</strong>.
                  </p>
                  <p>
                    <strong>Risorse illimitate</strong>
                  </p>
                  <p>
                    Scegliere un’<strong>infrastruttura a microservizi cloud-based</strong> dà
                    accesso a risorse di <strong>calcolo</strong>, di{" "}
                    <strong>spazio storage</strong>, di servizi pressochè infinite.
                  </p>
                  <p>
                    <strong>Tempi ridotti</strong>
                  </p>
                  <p>
                    I tempi di costruzione di una casa prefabbricata sono significativamente più
                    ridotti di un edificio che deve partire da zero. Allo stesso modo una soluzione
                    tecnologica basata su microservizi, permette di partire da “blocchi” di software
                    già pronto per ottimizzare tempi e costi.
                  </p>
                  <p>
                    <strong>Rischio azzerato</strong>
                  </p>
                  <p>
                    Per attività di sperimentazione e creazione di <strong>POC</strong>, le app
                    cloud-native sono la soluzione ideale. Consentono infatti di avviare il processo
                    senza assumersi i rischi e i costi tipici di un progetto. E’ permesso sbagliare
                    ed aggiustare il tiro. E non è cosa da poco..
                  </p>
                  <p>
                    <strong>Costi Pay per Use</strong>
                  </p>
                  <p>
                    Con le app cloud native viene ribaltato il vecchio paradigma: attenta{" "}
                    <strong>software selection</strong>, acquisto di un pacchetto software che
                    risponde alle esigenze di <strong>business, sviluppo</strong> della
                    applicazione. Con costi elevati e tempi che si misurano nell’ordine dei mesi.
                    Con un approccio pay-per-use{" "}
                    <strong>i costi sono basati sul reale utilizzo dei servizi</strong>; l’elevato
                    grado di scalabilità della soluzione consente di risparmiare in termini di costi
                    e tempi anche nelle fasi di sviluppo del progetto. I{" "}
                    <strong>singoli moduli</strong> (microservizi) sono infatti{" "}
                    <strong>sostituibili</strong> in ogni momento e <strong>scalabili</strong>{" "}
                    singolarmente, in modo da non dover intervenire sull’intera piattaforma.
                  </p>
                </div>
                <div
                  id="cloud-native-app-i-vantaggi-di-unarchitettura-basata-sui-microservizi"
                  className="anchor"
                >
                  <GatsbyImage
                    image={getImage(vantaggi)}
                    alt="Image vantaggi"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    Cloud Native App: i vantaggi di un'architettura basata sui microservizi
                  </h2>
                  <p>
                    <strong>Sistemi tailor made aderenti ai processi aziendali</strong>
                  </p>
                  <p>
                    Per le aziende che hanno l’esigenza di sistemi orientati al business, aderenti
                    ai processi aziendali, che possano integrarsi naturalmente nell’ecosistema
                    digitale, la soluzione proposta è la migliore possibile.
                    <br />
                    Per loro natura, le soluzioni tecnologiche basate sui microservizi permettono di
                    costruire sistemi molto aderenti alle esigenze specifiche, attraverso l’utilizzo
                    dei building blocks che, come mattoncini Lego, vanno a costruire la soluzione
                    desiderata.
                  </p>
                  <p>
                    <strong>Sviluppo incrementale</strong>
                  </p>
                  <p>
                    Sempre più nello sviluppo delle applicazioni digitali human-centered si
                    applicano metodologie agile che prevedono rilasci incrementali di semilavorati
                    direttamente utilizzabili. La metodologia è vincente anche nello sviluppo
                    tradizionale ed è quindi applicata dalle software house più innovative da anni.
                    Ma è nello sviluppo cloud-native che queste metodologie offrono il meglio.
                    Rispetto ad un To-Be desiderato, grazie ai microservizi, è possibile sviluppare
                    nell’immediato le componenti prioritarie, orchestrando i microservizi opportuni,
                    e aggiungere man mano i nice-to-have fino ad arrivare al prodotto desiderato.
                  </p>
                  <p>
                    <strong>Mobile first</strong>
                  </p>
                  <p>
                    In un mondo sempre più connesso e globale, l’approccio mobile first è ormai
                    imprescindibile.
                    <br />I microservizi sono già componenti pronte per architetture API-based che
                    permettono di sviluppare applicazioni software accessibili da ogni device e in
                    ogni momento.
                  </p>
                  <p>
                    <strong>Sicurezza e scalabilità</strong>
                  </p>
                  <p>
                    L’utilizzo del Cloud permette di svincolarsi da eventuali problemi di
                    affidabilità dell'infrastruttura e permettono di scalare nella crescita
                    dimensionale. Il cloud si rivela anche un importante driver di innovazione per
                    le aziende in termini di potenziale di crescita e sviluppo delle piattaforme.
                  </p>
                  <p>
                    <strong>Durata nel tempo</strong>
                  </p>
                  <p>
                    La modularità, la flessibilità e la semplicità di refactoring fanno delle cloud
                    native app la soluzione ideale per durare nel tempo restando sempre aggiornate
                    secondo le specifiche esigenze.
                  </p>
                  <p>
                    <strong>Costi</strong>
                  </p>
                  <p>
                    Le applicazioni in Saas rappresentano sicuramente un grande vantaggio
                    competitivo anche in termini di budget saving, soprattutto in scenari e
                    architetture software in rapida evoluzione. Viene stravolto il paradigma
                    dell’acquisto di licenze software che impattano con impegni di spesa importanti
                    “upfront” , per privilegiare il servizio “a consumo” più snello.
                    <br />E senza dimenticare che, qualora un microservizio risultasse
                    particolarmente costoso (in quanto molto utilizzato o semplicemente perchè
                    “caro”), c’e’ sempre la possibilità di svilupparne una versione custom da
                    eseguire su VM dimensionate opportunamente.
                  </p>
                </div>
                <div id="perche-aws" className="anchor">
                  <GatsbyImage
                    image={getImage(perche)}
                    alt="Image perchè aws"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">Perché AWS?</h2>
                  <p>
                    <strong>Affidabilità dello stack tecnologico</strong>
                  </p>
                  <p>
                    In un processo di software selection finalizzato alla realizzazione di un
                    sistema mission-critical, si tende a privilegiare soluzioni tecnologiche che
                    utilizzano piattaforme tecnologiche di largo impiego, leader di mercato, più
                    affidabili e presumibilmente supportate da un numero ampio di system integrator.
                    <br />
                    Sviluppare cloud native apps su infrastruttura AWS significa appoggiarsi alla
                    piattaforma leader assoluta di mercato che assicura flessibilità, affidabilità e
                    alte prestazioni. AWS è il leader di mercato nel Cloud, ha un'offerta completa
                    di servizi innovativi e sicuri, adatti a aziende di ogni dimensione e che
                    spaziano dal calcolo, allo storage, all'IoT, al Machine Learning, fino
                    all'analisi dei big-data. Ariadne è Consulting Partner Certificato Amazon Web
                    Services dal 2017 e vanta un competence center dedicato di oltre 15 persone.
                  </p>
                  <p>
                    <a
                      href="https://aws.amazon.com/it/resources/analyst-reports/?analyst-reports-main.sort-by=item.additionalFields.datePublished&amp;analyst-reports-main.sort-order=desc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Leggi il report degli analisti
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://pages.awscloud.com/GLOBAL-multi-DL-gartner-mq-cips-2020-learn.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gartner Report
                    </a>
                  </p>
                </div>
                <div id="progetti-realizzati-su-liferay-dxp" className="anchor">
                  <GatsbyImage
                    image={getImage(modelli)}
                    alt="Image modelli dei prezzi"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">Il modello dei prezzi AWS</h2>
                  <p>
                    Le tre componenti chiave su cui si basa il modello dei prezzi dei servizi AWS
                    sono:
                  </p>
                  <ul>
                    <li>pagamento a consumo dei servizi utilizzati;</li>
                    <li>
                      due schemi di risparmio che permettono di raggiungere fino al 66% o
                      addirittura al 72% di sconto sui prezzi di listino;
                    </li>
                    <li>
                      riduzione ulteriore di prezzo per volumi raggiunti di utilizzo dei servizi.
                    </li>
                  </ul>
                  <p>I tre fattori di costo che influenzano il modello dei prezzi:</p>
                  <ul>
                    <li>capacità di calcolo erogato calcolato per ora di utilizzo;</li>
                    <li>capacità di archiviazione dei dati calcolati per volume di GB gestiti;</li>
                    <li>
                      capacità di trasferimento dei dati in rete calcolati per volume di GB gestiti.
                    </li>
                  </ul>
                  <p>
                    Ovviamente servizi diversi presentano caratteristiche e funzionalità diverse per
                    cui hanno prezzi diversi. I servizi disponibili sono circa 200. Per una lista
                    completa si consiglia di consultare il portale AWS dove sono disponibili anche i
                    prezzi aggiornati per ciascun servizio cloud.
                  </p>
                  <p>
                    <strong>La struttura dei prezzi</strong>
                  </p>
                  <p>
                    Prendiamo a titolo di esempio tre servizi AWS al fine di conoscerne la struttura
                    dei prezzi più che il prezzo aggiornato in sé: EC2, EBS, S3.
                  </p>
                  <p>
                    EC2 per il calcolo può essere acquistato in cinque modalità differenti:
                    on-demand instances (erogato per ora o secondi), dedicated hosts (server
                    fisico), reserved instances (periodo da 1 a 3 anni), savings plans (periodo da 1
                    a 3 anni), spot instances (fino al 90% di sconto sui prezzi).
                  </p>
                  <p>
                    EBS per volumi di storage a blocchi può essere acquistato in tre modalità
                    differenti: per volumi (GB al mese), per snapshots (backup dei volumi) oppure
                    per data-transfer (traffico gratis in inbound ed a pagamento in outbound).
                  </p>
                  <p>
                    S3 per lo storage scalabile può essere acquistato in cinque modalità differenti:
                    per quantità di storage/dati gestiti, per regione che eroga il servizio, per
                    classe di storage, per numero e tipo di richiesta (GET, PUT, COPY), per quantità
                    di dati trasferiti fuori dalla regione che eroga il servizio.
                  </p>
                  <p>
                    <strong>Il piano gratuito di AWS</strong>
                  </p>
                  <p>
                    Il piano gratuito di AWS include offerte senza alcun costo, altre con una
                    scadenza dopo 12 mesi dal momento dell'iscrizione e altre ancora con prova
                    gratuita a breve termine. Ci sono alcuni servizi gratuiti (es. VPC, Bean, IAM,
                    CloudFormation, ecc.). Nel periodo di prova è possibile attivare un account
                    gratuito, beneficiare di tutorial nella fase di avviamento e iniziare a
                    sperimentare i servizi con AWS Free Tier. Esiste il tool AWS Simple Monthly
                    Calculator per verificare preventivamente i costi dei servizi in tre modalità
                    differenti: stima per mese, stima per servizio, stima per cliente; il tool AWS
                    Trusted Advisor per ridurre i prezzi lavorando per variabili differenti quali
                    l’ottimizzazione dei costi, le performance richieste, il livello di sicurezza
                    richiesto, ecc; e il tool AWS Cost Explorer per disporre di report già pronti e
                    redigere dei forecast di utilizzo.
                  </p>
                  <p>
                    <strong>Controllo costi e risorse</strong>
                  </p>
                  <p>
                    In pochi semplici passi è possibile tenere sotto controllo i costi delle risorse
                    di AWS:
                  </p>
                  <ul>
                    <li>calibrazione delle risorse in base alle esigenze a costi più bassi;</li>
                    <li>riduzione delle spese tramite prenotazione di capacità;</li>
                    <li>uso di istanze Spot;</li>
                    <li>monitoraggio e controllo dell'utilizzo dei servizi;</li>
                    <li>uso di Cost Explorer per ottimizzare le spese.</li>
                  </ul>
                  <p>
                    Il calcolatore di TCO consente di fare una stima dei risparmi che è possibile
                    ottenere usando il cloud rispetto ad ambienti tradizionali. Il calcolatore di
                    TCO offre report ed analisi dettagliati sui componenti di costo che rendono AWS
                    una valida opzione per ridurre i costi aziendali.
                  </p>
                  <p>
                    AWS non richiede importi minimi di spesa né impegni contrattuali a lungo
                    termine. I pagamenti anticipati vengono sostituiti con costi ridotti variabili
                    che si applicano solo in caso di effettivo utilizzo. Con AWS non è più
                    necessario vincolarsi in contratti pluriennali o complessi modelli di licenza.
                    La tendenza è comunque quella di andare verso la gestione a “transazione
                    gestita” come metodo di prezzo.
                  </p>
                  <p>
                    <strong>Supporto tecnico</strong>
                  </p>
                  <p>
                    Per quanto riguarda il supporto tecnico esistono quattro livelli disponibili:
                    basic, developer, business, enterprise. Ciascuno presenta livelli di SLA
                    differenti, anche h24x7 con tempi di risposta diversi a seconda del servizio e
                    variano dalle 24 ore fino ai 15 minuti.
                  </p>
                  <p>
                    Esiste una metodologia innovativa che si chiama AWS Architected Framework che
                    consente di verificare l’architettura del sistema in cloud sulla base di fattori
                    differenti quali la sicurezza e le performance del servizio da erogare in rete.
                  </p>
                  <p>
                    Le nostre soluzioni su misura per voi Ariadne è a fianco dei propri Clienti
                    nello sviluppo di progetti di successo. Nel caso si renda necessario un processo
                    di revisione dei costi dei servizi cloud AWS, Ariadne è disponibile a studiare
                    soluzioni gestite e dedicate che consentano il monitoraggio puntuale dei costi
                    rispetto alla soluzione standard on-demand
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default CloudNativeApps;

export const query = graphql`
  query {
    vantaggi: file(relativePath: { eq: "pages/cloud-native-apps-vantaggi.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    perche: file(relativePath: { eq: "pages/cloud-native-apps-perche.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    modelli: file(relativePath: { eq: "pages/cloud-native-apps-modelli.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    aws: file(relativePath: { eq: "pages/cloud-native-apps-aws.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
  }
`;
